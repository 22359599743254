<template>
  <v-container id="rfp-page" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12">
        <base-material-card color="primary" class="px-5 py-3">
          <template v-slot:heading>
            <v-tabs
              v-model="directSubscribers"
              background-color="transparent"
              slider-color="white"
            >
              <span
                class="display-2 font-weight-light mx-3"
                style="align-self: center"
              >Subscribers:</span
              >
              <v-tab class="mr-3">
                <font-awesome-icon :icon="['fas', 'user']" class="mr-2"/>
                Direct Contact Subscribers
              </v-tab>
              <v-tab class="mr-3">
                <font-awesome-icon :icon="['fas', 'user']" class="mr-2"/>
                Open Tenders Subscribers
              </v-tab>
            </v-tabs>
          </template>
          <v-tabs-items v-model="directSubscribers" class="transparent">
            <!-- Direct Contact subscribers -->
            <v-tab-item>
              <v-card-text>
                <v-row
                  class="mx-0 mb-0"
                  align="center"
                  justify="center"
                  justify-md="start"
                >
                  <v-col cols="12" md="8" lg="5">
                    <v-text-field
                      v-model="searchDirect"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      outlined
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-data-table
                  :headers="activeHeaders"
                  :search="searchDirect"
                  :items="directContactItems"
                  :items-per-page="15"
                  :loading="isLoading"
                >
                  <template v-slot:item.status="{ item }">
                    <v-btn
                      icon
                      color="black lighten-1"
                      @click="openDialog(item,0)"
                    >
                      <font-awesome-icon
                        :icon="getStatusIcon(item.status)"
                        :class="getStatusColorClass(item.status)"
                        size="lg"
                      />
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-tab-item>
            <!-- open tenders subscribers -->
            <v-tab-item>
              <v-card-text>
                <v-row
                  class="mx-0 mb-0"
                  align="center"
                  justify="center"
                  justify-md="start"
                >
                  <v-col cols="12" md="8" lg="5">
                    <v-text-field
                      v-model="searchTender"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      outlined
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-data-table
                  :headers="openTenderHeader"
                  :search="searchTender"
                  :items="tenderItems"
                  :items-per-page="15"
                  :loading="isLoading"
                >
                  <template v-slot:item.status="{ item }">
                    <v-btn
                      icon
                      color="black lighten-1"
                      @click="openDialog(item,1)"
                    >
                      <font-awesome-icon
                        style="cursor: pointer"
                        :icon="getStatusIcon(item.status)"
                        :class="getStatusColorClass(item.status)"
                        size="lg"
                      />
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </base-material-card>
        <v-dialog v-model="dialog" scrollable max-width="600px">
          <v-card
            class="mx-auto px-2 px-sm-10"
            max-width="600px"
            outlined
            style="height: 550px; overflow-y: auto"
          >
            <v-card-text></v-card-text>
            <v-row class="pb-3">
              <v-col
                cols="8"
                lg="10"
                class="d-flex flex-column justify-center align-start py-0"
              >
                <h1 class="headline text-left">Request Details</h1>
              </v-col>
              <v-col
                cols="4"
                lg="2"
                class="d-flex flex-column justify-center align-end py-0"
              >
                <v-btn icon color="grey lighten-1" @click="closeDialog">
                  <v-icon class="dialog-close-icon">mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="pb-3">
              <v-col
                cols="8"
                lg="10"
                class="d-flex flex-column justify-center align-start py-0"
              >
                <h4 class="subtitle text-left">Contact Member Details</h4>
              </v-col>
            </v-row>

            <v-row class="pb-3">
              <v-col
                cols="12"
                md="3"
                class="d-flex flex-column justify-center align-start py-0"
              >
                <span class="subtitle-1 text-left"> Name:</span>
              </v-col>
              <v-col
                cols="12"
                md="9"
                class="d-flex flex-column justify-center align-start py-0"
              >
                <span class="subtitle-1 text-left">{{
                    selectedItem.name
                  }}</span>
              </v-col>
            </v-row>

            <v-row class="pb-3">
              <v-col
                cols="12"
                md="3"
                class="d-flex flex-column justify-center align-start py-0"
              >
                <span class="subtitle-1 text-left"> Mobile:</span>
              </v-col>
              <v-col
                cols="12"
                md="9"
                class="d-flex flex-column justify-center align-start py-0"
              >
                <span class="subtitle-1 text-left">{{
                    selectedItem.mobile
                  }}</span>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="3"
                class="d-flex flex-column justify-center align-start py-0"
              >
                <span class="subtitle-1 text-left"> Email:</span>
              </v-col>
              <v-col
                cols="12"
                md="9"
                class="d-flex flex-column justify-center align-start py-0"
              >
                <span class="subtitle-1 text-left">
                  {{ selectedItem.email }}</span
                >
              </v-col>
            </v-row>
            <br/>
            <hr class="hr-divider w-100"/>
            <v-row class="pb-3">
              <v-col
                cols="12"
                md="3"
                class="d-flex flex-column justify-center align-start py-0 mt-3"
              >
                <h4 class="subtitle text-left">Billing Details</h4>
              </v-col>
            </v-row>
            <v-row class="pb-3">
              <v-col
                cols="12"
                md="3"
                class="d-flex flex-column justify-start align-start py-0"
              >
                <span class="subtitle-1 text-left"> Address:</span>
              </v-col>
              <v-col
                cols="12"
                md="9"
                class="d-flex flex-column justify-center align-start py-0"
              >
                <span class="subtitle-1 text-left">
                  {{ selectedItem.address }}</span
                >
              </v-col>
            </v-row>
            <v-row class="pb-3">
              <v-col
                cols="12"
                md="3"
                class="d-flex flex-column justify-center align-start py-0"
              >
                <span class="subtitle-1 text-left"> Company:</span>
              </v-col>
              <v-col
                cols="12"
                md="9"
                class="d-flex flex-column justify-center align-start py-0"
              >
                <span class="subtitle-1 text-left">
                  {{ selectedItem.company }}</span
                >
              </v-col>
            </v-row>

            <v-row class="pb-3">
              <v-col
                cols="12"
                md="3"
                class="d-flex flex-column justify-center align-start py-0"
              >
                <span class="subtitle-1 text-left"> PO No:</span>
              </v-col>
              <v-col
                cols="12"
                md="9"
                class="d-flex flex-column justify-center align-start py-0"
              >
                <span class="subtitle-1 text-left">
                  {{
                    selectedItem.phoneNo ? selectedItem.phoneNo : "N/A"
                  }}</span
                >
              </v-col>
            </v-row>

            <v-row class="pb-3">
              <v-col
                cols="12"
                md="3"
                class="d-flex flex-column justify-center align-start py-0"
              >
                <span class="subtitle-1 text-left"> VAT Number:</span>
              </v-col>
              <v-col
                cols="12"
                md="9"
                class="d-flex flex-column justify-center align-start py-0"
              >
                <span class="subtitle-1 text-left"
                >{{ selectedItem.vatNo }}
                </span>
              </v-col>
            </v-row>

            <br/>
            <hr class="hr-divider w-100"/>
            <v-row class="pb-3 mt-2">
              <v-col
                cols="12"
                md="3"
                class="d-flex flex-column justify-center align-start py-0"
              >
                <h4 class="subtitle text-left">Request Type</h4>
              </v-col>
              <v-col
                cols="12"
                md="9"
                class="d-flex flex-column justify-center align-start py-0"
              >
                <h5 class="typeText subtitle text-left">{{ approvalType === 0 ? 'Direct Contact' : 'Open Tender' }}</h5>
              </v-col>
            </v-row>

            <v-row class="pb-3">
              <v-col
                cols="12"
                md="3"
                class="d-flex flex-column justify-center align-start py-0"
              >
                <h4 class="subtitle text-left">Approve State</h4>
              </v-col>
              <v-col
                cols="12"
                md="4"
                class="d-flex flex-column justify-center align-sm-end px-0"
              >
                <v-checkbox
                  v-model="selectedItem.approve"
                  label="Approve"
                  label-position="right"
                  style="color: black"
                  @click="handleApproval"
                ></v-checkbox>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" md="4">
                <v-checkbox
                  v-model="selectedItem.reject"
                  label="Reject"
                  label-position="right"
                  style="color: black"
                  @click="handleRejection"
                ></v-checkbox>
              </v-col>
            </v-row>

            <v-row class="pb-3">
              <v-col
                cols="8"
                lg="10"
                class="d-flex flex-column justify-center align-start py-0"
              >
                <h4 class="subtitle text-left">Validation Period</h4>
              </v-col>
            </v-row>

            <v-row justify="space-between">
              <v-col cols="12" md="6">
                <v-menu
                  v-model="fromMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      label="Start Date"
                      readonly
                      outlined
                      :placeholder="'Start Date'"
                      :value="fromDate"
                      v-bind="attrs"
                      v-on="on"
                      height="55px"
                      @click="fromMenu = true"
                    >
                      <template v-slot:append>
                        <v-icon @click="fromMenu = true">mdi-calendar</v-icon>
                      </template>
                    </v-text-field
                    >
                  </template>
                  <v-date-picker
                    class="datepicker"
                    v-model="fromDate"
                    @input="fromMenu = false"
                    :min="new Date().toISOString().substr(0, 10)"
                    no-title
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" md="6">
                <v-menu
                  v-model="toMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      label="Expiry Date"
                      readonly
                      outlined
                      :placeholder="'Expiry Date'"
                      :value="toDate"
                      v-bind="attrs"
                      v-on="on"
                      height="55px"
                      :disabled="!fromDate"
                      @click="toMenu = true"
                    >
                      <template v-slot:append>
                        <v-icon @click="toMenu = true">mdi-calendar</v-icon>
                      </template>
                    </v-text-field
                    >
                  </template>
                  <v-date-picker
                    class="datepicker"
                    v-model="toDate"
                    @input="toMenu = false"
                    :min="fromDate"
                    no-title
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-card-actions class="px-6">
              <v-row
                class="d-flex flex-column-reverse flex-sm-row align-center justify-space-between mx-0"
              >
                <v-col
                  cols="12"
                  sm="3"
                  class="d-flex flex-column align-center align-sm-start px-0"
                >
                  <v-btn
                    class="px-8"
                    color="secondary"
                    @click="closeDialog"
                    tile
                    outlined
                    depressed
                    bottom
                  >Cancel
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  class="d-flex flex-column align-center align-sm-end px-0"
                >
                  <v-btn
                    class="px-8"
                    @click="paymentApprovalSubmitFn(selectedItem)"
                    color="secondary"
                    tile
                    depressed
                    bottom
                    right
                    :loading="isLoading"
                  >Submit
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DirectContact from "../../../services/directContact.service";
import Tenders from "../../../services/tender.service";

import DateFormatYYMMDD from "../../../mixin/DateFormatYYMMDD";

export default {
  name: "Direct-Contact",

  data() {
    return {
      selectedItem: {},
      dialog: false,
      directContactItems: [],
      tenderItems: [],
      name: null,
      mobile: null,
      email: null,
      company: null,
      address: null,
      vatNo: null,
      errorName: null,
      erroMobile: null,
      errorEmail: null,
      errorCompany: null,
      errorAddress: null,
      errorVatNo: null,
      searchTender: "",
      searchDirect: "",
      fromDate: new Date().toISOString().substr(0, 10),
      toDate: new Date().toISOString().substr(0, 10),
      errorStartDate: null,
      errorEndDate: null,
      approve: false,
      reject: false,
      isLoading: false,
      fromMenu: null,
      toMenu: null,
      isApiLoader: null,
      directSubscribers: 0,
      approvalType: 0
    };
  },

  computed: {
    activeHeaders() {
      return [
        {
          sortable: true,
          text: "Name",
          value: "name",
          class: "primary--text title",
        },
        {
          sortable: true,
          text: "Mobile ",
          value: "mobile",
          class: "primary--text title",
        },
        {
          sortable: true,
          text: "Email",
          value: "email",
          class: "primary--text title",
        },
        {
          sortable: true,
          text: "Company",
          value: "company",
          class: "primary--text title",
        },
        {
          sortable: true,
          text: "Status",
          value: "status",
          class: "primary--text title",
        }
      ];
    },
    openTenderHeader() {
      return [
        {
          sortable: true,
          text: "Name",
          value: "name",
          class: "primary--text title",
        },
        {
          sortable: true,
          text: "Mobile ",
          value: "mobile",
          class: "primary--text title",
        },
        {
          sortable: true,
          text: "Email",
          value: "email",
          class: "primary--text title",
        },
        {
          sortable: true,
          text: "Company",
          value: "company",
          class: "primary--text title",
        },
        {
          sortable: true,
          text: "Status",
          value: "status",
          class: "primary--text title",
        }
      ];
    },
  },
  watch: {
    toDate() {
      this.errorEndDate = null;
    },
  },
  mounted() {
    const currentDate = new Date();
    const oneYearFromNow = new Date(currentDate);
    oneYearFromNow.setFullYear(currentDate.getFullYear() + 1);
    this.toDate = oneYearFromNow.toISOString().substr(0, 10);
    this.isLoading = true;
    DirectContact.getAllDirContacts().then((res) => {
      this.directContactItems = res.data.filter((x) => x.status === 'Pending');
      this.isLoading = false;
    });

    Tenders.getAllOpenTenders().then((res) => {
      this.tenderItems = res.data.filter((x) => x.status === 'Pending');
      this.isLoading = false;
    });
  },
  methods: {
    openDialog(item, type) {
      this.approvalType = type;
      this.selectedItem = item;
      this.selectedItem.approve = item.status === "Accepted";
      this.selectedItem.reject = item.status === "Rejected";
      if (item.start) {
        this.fromDate = DateFormatYYMMDD(item.start);
      } else {
        this.fromDate = DateFormatYYMMDD(new Date().toISOString().substr(0, 10));
      }
      if (item.expire) {
        this.toDate = DateFormatYYMMDD(item.expire);
      } else {
        const currentDate = new Date();
        const oneYearFromNow = new Date(currentDate);
        oneYearFromNow.setFullYear(currentDate.getFullYear() + 1);
        this.toDate = DateFormatYYMMDD(oneYearFromNow.toISOString().substr(0, 10));
      }
      this.dialog = true;
      console.log(this.selectedItem);
    },

    closeDialog() {
      this.dialog = false;
    },

    paymentApprovalSubmitFn(val) {
      console.log(val);
      this.isApiLoader = true;

      let selectedOption = null;
      if (this.selectedItem.approve) {
        selectedOption = "Accepted";
      } else if (this.selectedItem.reject) {
        selectedOption = "Rejected";
      } else {
        selectedOption = null;
      }

      let payload = {
        _id: this.selectedItem._id,
        status: selectedOption,
        start: this.fromDate,
        expire: this.toDate,
      };

      if (this.directSubscribers === 0) {
        console.log("test 1");
        DirectContact.approveDirectContact(payload).then(() => {
          let payload =
            selectedOption === "Rejected"
              ? {
                snackBarText: `Direct contact subscriber '${val.name}' has been rejected`,
                snackBarColor: "secondary",
              }
              : {
                snackBarText: `Direct contact subscriber '${val.name}' has been approved`,
                snackBarColor: "success",
              };
          this.$store.dispatch("notify/setSnackbarMessage", payload);
        });
        DirectContact.getAllDirContacts().then((res) => {
          this.directContactItems = res.data;
        });
        Tenders.getAllOpenTenders().then((res) => {
          this.tenderItems = res.data;
        });
      } else {
        console.log("test 2");
        Tenders.approveOpenTender(payload).then(() => {
          let payload =
            selectedOption === "Rejected"
              ? {
                snackBarText: `Open tender subscriber '${val.name}' has been rejected`,
                snackBarColor: "secondary",
              }
              : {
                snackBarText: `Open tender subscriber '${val.name}' has been approved`,
                snackBarColor: "success",
              };
          this.$store.dispatch("notify/setSnackbarMessage", payload);
        });
        Tenders.getAllOpenTenders().then((res) => {
          this.tenderItems = res.data;
        });
      }
      this.dialog = false;
    },
    getStatusIcon(status) {
      switch (status) {
        case "Pending":
          return ["fas", "clock"];
        case "Accepted":
          return ["fas", "check-square"];
        case "Rejected":
          return ["fas", "ban"];
        default:
          return null;
      }
    },
    getStatusColorClass(status) {
      switch (status) {
        case "Pending":
          return "primary--text";
        case "Accepted":
          return "success--text";
        case "Rejected":
          return "error--text";
        default:
          return "";
      }
    },
    handleApproval() {
      this.selectedItem = {
        ...this.selectedItem,
        approve: true,
        reject: false,
      };
    },
    handleRejection() {
      this.selectedItem = {
        ...this.selectedItem,
        approve: false,
        reject: true,
      };
    },
  },
};
</script>


<style scoped>
.date-picker {
  width: 100%;
}

.v-menu__content .v-date-picker {
  max-width: none;
}

.v-date-picker {
  max-width: none !important;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0);
}

.hr-divider {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid var(--v-platinum-base);
  margin: 0;
  padding: 0;
  margin-bottom: 16px;
}

.datepicker {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.typeText {
  color: #35a7ff;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

</style>
